<template>
  <div class="text-center">
    <v-dialog v-model="showDialog" width="600" persistent :retain-focus="false">
      <v-card>
        <div class="modal-title">
          <div class="modal-title-color">{{ formTitle }}</div>
          <v-btn depressed color="#1565c0" height="50px" width="40px" @click="close">
            <v-icon color="#64b5f6">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-switch
            color="green"
            class="d-inline-block"
            v-model="card.status"
            :label="cardStatus"
          ></v-switch>
          <v-form ref="form" lazy-validation>
            <v-text-field
              v-model="card.name"
              label="Name"
              required
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="card.cardType.name"
              label="Card Strategy"
              readonly
            >
            </v-text-field>
            <v-text-field
              v-model="card.tenantUUID"
              label="Platform Account"
              readonly
            ></v-text-field>
            <v-text-field
              v-model="card.validationPattern"
              label="Validation Pattern"
            ></v-text-field>
            <div class="checkbox-label">Tasks</div>
            <div v-for="task in allTasks" :key="task.id" class="d-inline-block pr-2">
              <v-checkbox
              :label="task.name"
              :value="task"
              multiple
              v-model="card.tasks">
              </v-checkbox>
            </div>
            <v-text-field
              label="Channel"
              v-model="card.channel"
              readonly
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="btn-action white--text" @click="close">Cancel</v-btn>
          <v-btn class="btn-action white--text" @click="save">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EditCard',
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required field',
    },
    editedCard: {},
    formTitle: 'Edit customer card',
    showDialog: false,
  }),
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.updateCard();
      }
    },
    getCard() {
      this.$store.dispatch('loadCard', this.$route.params.id);
    },
    getCards() {
      this.$store.dispatch('loadCards');
    },
    close() {
      this.showDialog = false;
      this.$router.push({ name: 'home' });
    },
    updateCard() {
      const {
        name, validationPattern, channel, status,
      } = this.card;
      this.editedCard = {
        name, validationPattern, channel, status,
      };
      this.editedCard.tasks = this.card.tasks.map((task) => task.textId);
      this.$store.dispatch('updateCard', [this.$route.params.id, this.editedCard])
        .then(() => this.close());
    },
  },
  computed: {
    ...mapGetters(['card', 'allTasks']),
    cardStatus() {
      return this.card.status ? 'Active' : 'Inactive';
    },
  },
  created() {
    this.getCard();
    this.showDialog = true;
  },
};
</script>

<style scoped lang="scss">
.checkbox-label {
  color: #1976d2;
}
.modal-title {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  background: #1976d2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-color {
    color: #64b5f6;
  }
}
</style>
